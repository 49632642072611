import {App, reactive, ref, watch} from 'vue'
import {GeojsonCoordinate, GeojsonFeature} from "/@/types/map";
import pin2d8cf0 from "/@/assets/icons/pin-2d8cf0.svg";
import {EFlightAreaType, GeojsonCircle} from "/@/types/flight-area";
import pin19be6b from "/@/assets/icons/pin-19be6b.svg";
import pin212121 from "/@/assets/icons/pin-212121.svg";
import pinb620e0 from "/@/assets/icons/pin-b620e0.svg";
import pine23c39 from "/@/assets/icons/pin-e23c39.svg";
import pineffbb00 from "/@/assets/icons/pin-ffbb00.svg";
import {MapDoodleType} from "/@/constants/map";

const markerColors: {
    [key: string]: any
} = {
    '#2d8cf0': pin2d8cf0,
    '#19be6b': pin19be6b,
    '#212121': pin212121,
    '#b620e0': pinb620e0,
    '#e23c39': pine23c39,
    '#ffbb00': pineffbb00,
}

const flightAreaColors = {
    [EFlightAreaType.DFENCE]: '#19be6b',
    [EFlightAreaType.NFZ]: '#ff0000',
}

export function useGMapManage(overlayCompleteCallback: Function, readyCallback?: Function) {

    let _map: google.maps.Map
    let _drawingManager: google.maps.drawing.DrawingManager

    async function initMap(app: App) {
        console.log('Loading Google Maps...')

        const location = {
            lat: 47.274208,
            lng: 18.759988
        };

        try {
            const pos: any = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            })

            if (pos) {
                location.lat = pos.coords.latitude
                location.lng = pos.coords.longitude
            }

        } catch (err) {
            console.error(err)
        }

        // Initial map options
        const mapOptions = {
            center: location,
            zoom: 15,
            fullscreenControl: false,
            mapTypeId: 'satellite', // Set the default map type to satellite
            streetViewControl: false,
            tilt: 0,
            mapId: 'DEMO_MAP_ID'
        }

        const {Map} = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        const {AdvancedMarkerElement} = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
        const {DrawingManager} = await google.maps.importLibrary('drawing') as google.maps.DrawingLibrary

        // Construct map
        const map = new Map(document.getElementById('g-container')!!, mapOptions)

        _map = map
        app.config.globalProperties.$gMap = map

        // Construct drawing manager
        const drawingManager = new DrawingManager({
            map: map,
            drawingControl: false,
        })

        _drawingManager = drawingManager
        app.config.globalProperties.$gMapDrawingManager = drawingManager

        // Register events for drawing completion
        google.maps.event.addListener(drawingManager, 'overlaycomplete', (event: any) => {
            overlayCompleteCallback(event)

            // Remove the overlay from the map as it will be redrawn from the store
            // event.overlay.setMap(null)
        })
    }

    async function drawMarker() {
        _drawingManager.setOptions({
            drawingMode: google.maps.drawing.OverlayType.MARKER,
            markerOptions: {
                icon: pin2d8cf0,
                title: 'marker'
            }
        })
    }

    function drawPolyLine() {
        _drawingManager.setOptions({
            drawingMode: google.maps.drawing.OverlayType.POLYLINE,
            polylineOptions: {
                strokeColor: '#2d8cf0',
                strokeOpacity: 1,
                strokeWeight: 2,
                title: 'polyline'
            }
        })

    }

    function drawPolygon(options?: any) {
        _drawingManager.setOptions({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            polygonOptions: {
                strokeColor: '#2d8cf0',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#2d8cf0',
                fillOpacity: 0.3,
                title: 'polygon',
                ...options
            }
        })
    }

    function drawFlightArea(flightAreaType: EFlightAreaType, type: MapDoodleType) {
        if (type == 'polygon') {
            _drawingManager.setOptions({
                drawingMode: google.maps.drawing.OverlayType.POLYGON,
                polygonOptions: {
                    strokeColor: flightAreaColors[flightAreaType],
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: flightAreaColors[flightAreaType],
                    fillOpacity: 0.3,
                    extData: {
                        type: flightAreaType,
                        mapType: 'polygon'
                    }
                }
            })
        } else if (type == 'circle') {
            _drawingManager.setOptions({
                drawingMode: google.maps.drawing.OverlayType.CIRCLE,
                circleOptions: {
                    strokeColor: flightAreaColors[flightAreaType],
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: flightAreaColors[flightAreaType],
                    fillOpacity: 0.3,
                    extData: {
                        type: flightAreaType,
                        mapType: 'circle'
                    }
                }
            })
        }
    }


    // This function draws a primitive to the map
    function drawOverlay(overlayObject: GeojsonFeature) {
        let createdOverlay = null;

        const geometry = overlayObject.geometry
        const properties = overlayObject.properties

        switch (overlayObject.geometry.type) {
            case 'Point': {
                const coords = geometry.coordinates as GeojsonCoordinate
                // Icon for the marker
                const markerIcon = document.createElement('img');
                markerIcon.src = markerColors[properties.color.toLowerCase()];

                createdOverlay = new google.maps.marker.AdvancedMarkerElement({
                    map: _map,
                    position: {lat: coords[1], lng: coords[0]},
                    content: markerIcon
                })

                break;
            }
            case 'LineString': {
                createdOverlay = new google.maps.Polyline({
                    map: _map,
                    path: (geometry.coordinates as GeojsonCoordinate[]).map((coords: GeojsonCoordinate) => ({
                        lat: coords[1],
                        lng: coords[0]
                    })),
                    strokeColor: properties.color,
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                })

                break;
            }

            case 'Polygon': {
                const coords = geometry.coordinates as GeojsonCoordinate[][];
                createdOverlay = new google.maps.Polygon({
                    map: _map,
                    paths: coords[0].map((c: GeojsonCoordinate) => ({lat: c[1], lng: c[0]})),
                    strokeColor: properties.color,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: properties.color,
                    fillOpacity: 0.3,
                })


                break;
            }
            case 'Circle': {
                const overlayCircle = overlayObject as GeojsonCircle;
                const coords = geometry.coordinates as GeojsonCoordinate;

                createdOverlay = new google.maps.Circle({
                    strokeColor: properties.color,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: properties.color,
                    fillOpacity: 0.3,
                    map: _map,
                    center: {lat: coords[1], lng: coords[0]},
                    radius: overlayCircle.geometry.radius,
                });

                break;
            }
        }

        return createdOverlay
    }

    function panTo(coordinate: google.maps.LatLng) {
        _map.panTo(coordinate)
        _map.setZoom(15)
    }

    function exitDrawMode() {
        _drawingManager.setDrawingMode(null)
    }

    return {
        initMap, drawMarker, drawPolyLine, drawOverlay, panTo, drawPolygon, exitDrawMode, drawFlightArea
    }
}
